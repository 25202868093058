<template>
  <div id="EditUser" class="row mx-0 calendar">
    <div class="row mx-0">
      <div id="header" class="col-sm-12 col-md-12  color-bg-4">
        <Header
                :name="user.fullname"
                /> 
      </div>
      <div class="col-sm-12 col-md-12">
        <div id="content" class="row color-bg-4">
          <div id="" class="">
             <SideMenu :indexsec="0"/>
          </div>
    
          <div id="stn-edituser" class="stn_edituserAdd">
            <form
              @submit.prevent="
                submit(
                  data.opname,
                  fecha,
                  data.result_c,
                  data.result_op,
                  data.hr,
                  data.type,
                  data.typegame,
                  data.timezone,
                  data.stadium,
                  file1,
                  team_form, tournament_form, profile_form
                )
              "
            >
            <div class="Div_EditPart"><p>EDITAR PARTIDO</p></div>

              <a class="btn-ver" :href="'/calendario/' + data.id" target="_blank"> VER CALENDARIO <img src="../../assets/Arrow_6.png" alt=""> </a>
            <div class="mtrow"></div>


              <div class="row mx-0 row-one">
                <div class="CEF1_B1">
                  <label class="labelN color-1" for="inputN">VS</label>
                  <b-form-input
                    class="Input_VS"
                    v-model="data.opname"
                    required
                    id="inputN"
                    type="text"
                  />
                </div>
                <div class="CEF1_B2">
                  <label class="labelA color-1" for="inputA"
                    >Fecha   <span class="f_actual">actual: {{data.descrp}}</span></label
                    
                  >
                  <b-form-input
                    class="Input_Fecha"
                    v-model="fecha"
                    id="inputA"
                    type="date"
                    
                   
                  />
                </div>
              </div>
               <div class="row mx-0 row-one mtrow">
                <div class="CEF2_B1">
                  <label class="labelN color-1" for="inputRc">Resultado Cimarrones</label>
                  <b-form-input
                    class="Input_RC"
                    v-model="data.result_c"
                    required
                    id="inputRc"
                    type="text"
                    pattern="\d*"
                  />
                </div>
                <div class="CEF2_B2">
                  <label class="labelA color-1" for="inputRop"
                    >Resultado oponente</label
                  >
                  <b-form-input
                    class="Input_RO"
                    v-model="data.result_op"
                    required
                    
                    id="inputRop"
                    pattern="\d*"
                    type="text"
                  />
                </div>
              </div>

              <div class="row mx-0 row-two">
                <div class="CEF3_B1">
                  <label class="labelE color-1" for="inputE">Hora</label>
                  <b-form-input   
                  class="Input_Hora"
                  v-model="data.hr" 
                  required 
                  id="inputE" 
                  type="time" />
                </div>
                <div class="CEF3_B2">
                  <label class="labelTel color-1" for="inputTel">Estadio</label>
                  <b-form-input
                    class="Input_Estadio"
                    v-model="data.stadium"
                    required
                    id="inputTel"
                    type="text"
                  />
                </div>
               
                <div class="CEF3_B3">
                  <label class="labelR color-1" for="inputR">Tipo</label>
                  <b-dropdown class="Button_Tipo">
                    <template #button-content>
                      <div class="row"> <span class="drop-text">{{data.type}}</span></div>
                    </template>

                    <b-dropdown-item @click="changeType('Local')">
                      Local
                    </b-dropdown-item>
                    <b-dropdown-item @click="changeType('Visitante')">
                      Visitante
                    </b-dropdown-item>
          
                  </b-dropdown>
                </div>
              </div>

                <div class="row mx-0 row-one mtrow">
                 <div class="CEF4_B1">
                  <label class="labelP color-1" for="inputP"
                    >Horario de</label
                  >
                  <b-form-input
                    class="Input_HorarioDe"
                    v-model="data.timezone"
                    required
                    id="inputP"
                    type="text"
                  />
                </div>
                  <div class="CEF4_B2">
                  <label class="labelP color-1" for="inputP"
                    >Tipo de partido</label
                  >
                  <b-form-input
                    class="Input_TP"
                    v-model="data.typegame"
                    required
                    id="inputP"
                    type="text"
                    :placeholder="'Jornada, Cuartos de final...'"
                  />
                </div>
              </div>

               <div class="row mx-0 row-two">
               
                <div class="CEF5_B1">
                    <label class="labelEq color-1" >Equipo</label>
                   
                     <b-dropdown>
                      <template #button-content>
                        <div class="row"><span class="drop-text">{{data.name_team}}</span></div> 
                      </template>

                       <div v-for="(item, key) in this.teams" :key="key">
                          <b-dropdown-item @click="changeTeam(item.id, item.name)">
                            {{item.name}}
                          </b-dropdown-item>
                       </div>
                    </b-dropdown>
                  </div>
                   <div class="CEF5_B2">
                    <label class="labelTr color-1" >Torneo</label>
                   
                     <b-dropdown>
                      <template #button-content>
                        <div class="row"><span class="drop-text">{{data.name_tournament}}</span></div> 
                      </template>

                       <div v-for="(item, key) in this.tournaments" :key="key">
                          <b-dropdown-item @click="changeTournament(item.id, item.name)">
                            {{item.name}}
                          </b-dropdown-item>
                       </div>
                    </b-dropdown>
                  </div>
                   <div class="CEF5_B3">
                    <label class="labelpr color-1" >Perfil</label>
                   
                     <b-dropdown>
                      <template #button-content>
                        <div class="row"><span class="drop-text">{{data.name_profile}}</span></div> 
                      </template>

                       <div v-for="(item, key) in this.profiles" :key="key">
                          <b-dropdown-item @click="changeProfile(item.id, item.name)">
                            {{item.name}}
                          </b-dropdown-item>
                       </div>
                    </b-dropdown>
                  </div>
                   
               
                
              </div>

              <div class="row mx-0 row-one mtrow">
                 <div class="div-image ">
                  <label class="labelImg color-1" for="inputImg">Imagen</label>
                  <b-form-file
                    v-model="file1"
                    id="inputImg"
                   
                    type="file"
                    name="inputImg"
                    :placeholder="'Subir imagen'"
                  />
                </div> 
                <div id="edit-calendar-img" v-if="msgimg == 'success'">
               <!-- <p class="color-1">Imagen del usuario:</p> -->
           
                 <img :src="this.image(data.image)"  alt="imagen de usuario">
                 </div>
              </div>
             


               <div  v-if="this.section==''" class="row mx-0 row-one">
                <div class="div-error" v-if="this.status=='error'">
                    <p class="msg-error">{{this.msg}}</p>
                </div>

              </div>
              <div id="EditPartbtn_Guardar1" class="btn-form">
                <b-btn 
                  variant="outline-secondary"
                  class="editar-modal "
                  type="submit"
                  >GUARDAR</b-btn
                >
                <b-modal
                  id="modal-editar"
                  ref="modal-editar"
                  no-close-on-backdrop
                  centered
                  hide-footer
                  hide-header
                >
                  <div class="message-modal">
                    <img :src="IconSuccess" class="success" /><br />
                    <p class="text-modal color-6">{{message_modal}}</p>
                  </div>
                  <div class="">
                    <b-button class="btn-modal" @click="editarOtro()"
                      >ACEPTAR</b-button
                    >
                    <b-button
                      type="button"
                      aria-label="Close"
                      class="close-modal"
                      @click="salir()"
                      >×</b-button
                    >
                  </div>
                </b-modal>
              </div>
            </form>

            <hr class="mtrow">
                  <label id="Label_AgrCanales" class="labelpr color-1" >Agregar Canales</label>
                  <!-- 
                  {{channels}}
                  <hr>
                  {{listChannels}} 
                  -->

                  <form
              @submit.prevent="
                agregarCanal(
                  channel_form
                )
              "
            >
              
              


              <div class="row mx-0 row-two">
               
                <div class="div_Canal">
                    <label id="label_subtitle" class="labelEq color-1" >Canal</label>
                   
                     <b-dropdown>
                      <template #button-content>
                        <div class="row"><span class="drop-text">{{channel_name}}</span></div> 
                      </template>

                       <div v-for="(item, key) in this.channels" :key="key">
                          <b-dropdown-item @click="changeChannel(item.id, item.name)">
                            {{item.name}}
                          </b-dropdown-item>
                       </div>
                    </b-dropdown>
                  </div>

              </div>

             


             
              <div id="EditPartbtn_Agregar" class="btn-form-2">
                <b-btn 
                  variant="outline-secondary"
                  class="editar-modal"
                  type="submit"
                  >AGREGAR</b-btn
                >
                <b-modal
                  id="modal-editar"
                  ref="modal-editar"
                  no-close-on-backdrop
                  centered
                  hide-footer
                  hide-header
                >
                  <div class="message-modal">
                    <img :src="IconSuccess" class="success" /><br />
                    <p class="text-modal color-6">{{message_modal}}</p>
                  </div>
                  <div class="">
                    <b-button class="btn-modal" @click="editarOtro()"
                      >ACEPTAR</b-button
                    >
                    <b-button
                      type="button"
                      aria-label="Close"
                      class="close-modal"
                      @click="salir()"
                      >×</b-button
                    >
                  </div>
                </b-modal>
              </div>
            </form>
         
           <div class="canales">
                    <div id="div_canales" class="row channel_titles">
                        <div>
                          <p>Nombre</p>
                        </div>
                        <div>
                          <p>Imagen</p>
                        </div>
                        <div>
                          <p>Eliminar</p>
                        </div>
                    </div>
                 
                  <div v-if="listChannels != 'No se encontraron coincidencias'">
                  <div  v-for="(item,key) in listChannels" class="item_channel" :key="key">
                        <div class="row">
                            <div>
                              <p>{{item.name_channel}}</p>
                            </div>
                            <div>
                              <p> <img :src="urlpath.url()+'/get-channel-image/'+item.image_channel" alt=""></p>
                            
                            </div>
                            <div>
                              <p class="eliminar-channel" @click="eliminarCanal(item.id)">&#10006;</p>
                            </div>
                        </div>
                    </div>
                  </div>
                  <div v-else>
                        <div class="mtrow msg_channels">
                            <p>Este partido aun no tiene canales asignados.</p>
                        </div>
                  </div>
                   
                    
           </div>

             <div v-if="this.section =='canales'" class="row mx-0 row-one">
                <div class="div-error" v-if="this.status=='error'">
                    <p class="msg-error">{{this.msg}}</p>
                </div>
              </div>

   <!--- gallery  -------------------------------------------------------------------------------------------------------------------------------------->
        <hr class="mtrow">
                  <label id="Label_AgrCanales" class="labelpr color-1" >Agregar Blog</label>
                
                  <form
              @submit.prevent="
                agregarBlog(
                  blog_form
                )
              "
            >
              
              


              <div class="row mx-0 row-two">
               
                <div class="div_Canal">
                    <label id="label_subtitle" class="labelEq color-1" >Blog</label>
                   
                     <b-dropdown>
                      <template #button-content>
                        <div class="row"><span class="drop-text">{{blog_name}}</span></div> 
                      </template>

                       <div v-for="(item, key) in this.blogs" :key="key">
                          <b-dropdown-item @click="changeBlog(item.id, item.title)">
                            {{item.title}}
                          </b-dropdown-item>
                       </div>
                    </b-dropdown>
                  </div>

              </div>

             


             
              <div id="EditPartbtn_Agregar" class="btn-form-2">
                <b-btn 
                  variant="outline-secondary"
                  class="editar-modal"
                  type="submit"
                  >AGREGAR</b-btn
                >
                <b-modal
                  id="modal-editar"
                  ref="modal-editar"
                  no-close-on-backdrop
                  centered
                  hide-footer
                  hide-header
                >
                  <div class="message-modal">
                    <img :src="IconSuccess" class="success" /><br />
                    <p class="text-modal color-6">{{message_modal}}</p>
                  </div>
                  <div class="">
                    <b-button class="btn-modal" @click="editarOtro()"
                      >ACEPTAR</b-button
                    >
                    <b-button
                      type="button"
                      aria-label="Close"
                      class="close-modal"
                      @click="salir()"
                      >×</b-button
                    >
                  </div>
                </b-modal>
              </div>
            </form>
         
           <div class="canales">
                    <div id="div_canales" class="row channel_titles">
                        <div>
                          <p>Nombre</p>
                        </div>
                        <div>
                          <p>Imagen</p>
                        </div>
                        <div>
                          <p>Eliminar</p>
                        </div>
                    </div>
                 
                  <div v-if="data.blog != 'No se encontraron coincidencias'">
                  <div class="item_channel" :key="key">
                        <div class="row">
                            <div>
                              <p>{{data.blog.title}}</p>
                            </div>
                            <div>
                              <p> <img :src="urlpath.url()+'/get-blog-image/'+data.blog.image" alt=""></p>
                            
                            </div>
                            <div>
                              <p class="eliminar-channel" @click="eliminarBlog(data.blog.id)">&#10006;</p>
                            </div>
                        </div>
                    </div>
                  </div>
                  <div v-else>
                        <div class="mtrow msg_channels">
                            <p>Este partido aun no tiene un blog asignado.</p>
                        </div>
                  </div>
                   
                    
           </div>

             <div v-if="this.section =='blog'" class="row mx-0 row-one">
                <div class="div-error" v-if="this.status=='error'">
                    <p class="msg-error">{{this.msg}}</p>
                </div>
              </div>



               <!--- gallery  -------------------------------------------------------------------------------------------------------------------------------------->
        <hr class="mtrow">
                  <label id="Label_AgrCanales" class="labelpr color-1" >Agregar Galería</label>
                
                  <form
              @submit.prevent="
                agregarGallery(
                  gallery_form
                )
              "
            >
              
              


              <div class="row mx-0 row-two">
               
                <div class="div_Canal">
                    <label id="label_subtitle" class="labelEq color-1" >Galería</label>
                   
                     <b-dropdown>
                      <template #button-content>
                        <div class="row"><span class="drop-text">{{gallery_name}}</span></div> 
                      </template>

                       <div v-for="(item, key) in this.gallerys" :key="key">
                          <b-dropdown-item @click="changeGallery(item.id, item.title)">
                            {{item.title}}
                          </b-dropdown-item>
                       </div>
                    </b-dropdown>
                  </div>

              </div>

             


             
              <div id="EditPartbtn_Agregar" class="btn-form-2">
                <b-btn 
                  variant="outline-secondary"
                  class="editar-modal"
                  type="submit"
                  >AGREGAR</b-btn
                >
                <b-modal
                  id="modal-editar"
                  ref="modal-editar"
                  no-close-on-backdrop
                  centered
                  hide-footer
                  hide-header
                >
                  <div class="message-modal">
                    <img :src="IconSuccess" class="success" /><br />
                    <p class="text-modal color-6">{{message_modal}}</p>
                  </div>
                  <div class="">
                    <b-button class="btn-modal" @click="editarOtro()"
                      >ACEPTAR</b-button
                    >
                    <b-button
                      type="button"
                      aria-label="Close"
                      class="close-modal"
                      @click="salir()"
                      >×</b-button
                    >
                  </div>
                </b-modal>
              </div>
            </form>
         
           <div class="canales">
                    <div id="div_canales" class="row channel_titles">
                        <div>
                          <p>Nombre</p>
                        </div>
                        <div>
                          <p>Imagen</p>
                        </div>
                        <div>
                          <p>Eliminar</p>
                        </div>
                    </div>
                 
                  <div v-if="data.gallery != 'No se encontraron coincidencias'">
                  <div  v-for="(item,key) in data.gallery" class="item_channel" :key="key">
                        <div class="row">
                            <div>
                              <p>{{item.title}}</p>
                            </div>
                            <div>
                              <p> <img :src="urlpath.url()+'/get-gallery-image/'+item.image" alt=""></p>
                            
                            </div>
                            <div>
                              <p class="eliminar-channel" @click="eliminarGallery(item.id)">&#10006;</p>
                            </div>
                        </div>
                    </div>
                  </div>
                  <div v-else>
                        <div class="mtrow msg_channels">
                            <p>Este partido aun no tiene videos asignados.</p>
                        </div>
                  </div>
                   
                    
           </div>

             <div v-if="this.section =='gallery'" class="row mx-0 row-one">
                <div class="div-error" v-if="this.status=='error'">
                    <p class="msg-error">{{this.msg}}</p>
                </div>
              </div>


     <!--- videos  -------------------------------------------------------------------------------------------------------------------------------------->
        <hr class="mtrow">
                  <label id="Label_AgrCanales" class="labelpr color-1" >Agregar Videos</label>
                
                  <form
              @submit.prevent="
                agregarVideo(
                  videos_form
                )
              "
            >
              
              


              <div class="row mx-0 row-two">
               
                <div class="div_Canal">
                    <label id="label_subtitle" class="labelEq color-1" >Video</label>
                   
                     <b-dropdown>
                      <template #button-content>
                        <div class="row"><span class="drop-text">{{videos_name}}</span></div> 
                      </template>

                       <div v-for="(item, key) in this.videos" :key="key">
                          <b-dropdown-item @click="changeVideos(item.id, item.title)">
                            {{item.title}}
                          </b-dropdown-item>
                       </div>
                    </b-dropdown>
                  </div>

              </div>

             


             
              <div id="EditPartbtn_Agregar" class="btn-form-2">
                <b-btn 
                  variant="outline-secondary"
                  class="editar-modal"
                  type="submit"
                  >AGREGAR</b-btn
                >
                <b-modal
                  id="modal-editar"
                  ref="modal-editar"
                  no-close-on-backdrop
                  centered
                  hide-footer
                  hide-header
                >
                  <div class="message-modal">
                    <img :src="IconSuccess" class="success" /><br />
                    <p class="text-modal color-6">{{message_modal}}</p>
                  </div>
                  <div class="">
                    <b-button class="btn-modal" @click="editarOtro()"
                      >ACEPTAR</b-button
                    >
                    <b-button
                      type="button"
                      aria-label="Close"
                      class="close-modal"
                      @click="salir()"
                      >×</b-button
                    >
                  </div>
                </b-modal>
              </div>
            </form>
         
           <div class="canales">
                    <div id="div_canales" class="row channel_titles">
                        <div>
                          <p>Nombre</p>
                        </div>
                        <div>
                          <p>Imagen</p>
                        </div>
                        <div>
                          <p>Eliminar</p>
                        </div>
                    </div>
                 
                  <div v-if="data.videos != 'No se encontraron coincidencias'">
                  <div  v-for="(item,key) in data.videos" class="item_channel" :key="key">
                        <div class="row">
                            <div>
                              <p>{{item.title}}</p>
                            </div>
                            <div>
                              <p> <img :src="urlpath.url()+'/get-video-image/'+item.image" alt=""></p>
                            
                            </div>
                            <div>
                              <p class="eliminar-channel" @click="eliminarVideo(item.id)">&#10006;</p>
                            </div>
                        </div>
                    </div>
                  </div>
                  <div v-else>
                        <div class="mtrow msg_channels">
                            <p>Este partido aun no tiene videos asignados.</p>
                        </div>
                  </div>
                   
                    
           </div>

             <div v-if="this.section =='videos'" class="row mx-0 row-one">
                <div class="div-error" v-if="this.status=='error'">
                    <p class="msg-error">{{this.msg}}</p>
                </div>
              </div>


      <!--- Estadisticas -------------------------------------------------------------------------------------------------------------------------------------->
              <hr class="mtrow">
            
            <div v-if="!this.compareDate()"> 
              <div class="estadisticas" v-if="data.estadisticas =='No se encontraron coincidencias'">
               <label class="labelpr color-1" >Registrar Estadisticas</label>

               <form
              @submit.prevent="
                agregarEstadisticas(
                  tag_c_form,
                  tde_c_form,
                  fl_c_form,
                  fts_c_form,
                  ta_c_form,
                  tr_c_form,
                  tag_o_form,
                  tde_o_form,
                  fl_o_form,
                  fts_o_form,
                  ta_o_form,
                  tr_o_form
                )
              "
            >
              <div class="row mx-0 row-one mtrow">
                <div class="">
                  <label class="labelCim color-1">Cimarrones</label>
                 
                </div>
                <div class="">
                  <label class="labelOp color-1" >Oponente</label>
                </div>
              </div>
               
               <div class="row mx-0 row-one">
                <div class="">
                  <label class="labelTagc color-1" for="inputTagc"><span class="e_text">Tiros a gol</span></label>
                  <b-form-input
                    v-model="tag_c_form"
                    required
                    id="inputTagc"
                    type="text"
                    pattern="\d*"
                  />
                </div>
                <div class="">
                  <label class="labelTago color-1" for="inputTago"><span class="e_text">Tiros a gol</span></label>
                  <b-form-input
                    v-model="tag_o_form"
                    required
                    
                    id="inputTago"
                    pattern="\d*"
                    type="text"
                  />
                </div>
              </div>

               <div class="row mx-0 row-one mtrow">
                <div class="">
                  <label class="labelTdec color-1" for="inputTdec"><span class="e_text">Tiros de esquina</span></label>
                  <b-form-input
                    v-model="tde_c_form"
                    required
                    id="inputTdec"
                    type="text"
                    pattern="\d*"
                  />
                </div>
                <div class="">
                  <label class="labelTdeo color-1" for="inputTdeo"><span class="e_text">Tiros de esquina</span></label>
                  <b-form-input
                    v-model="tde_o_form"
                    required
                    
                    id="inputTdeo"
                    pattern="\d*"
                    type="text"
                  />
                </div>
              </div>
               <div class="row mx-0 row-one mtrow">
                <div class="">
                  <label class="labelflc color-1" for="inputflc"><span class="e_text">Fueras de lugar</span></label>
                  <b-form-input
                    v-model="fl_c_form"
                    required
                    id="inputflc"
                    type="text"
                    pattern="\d*"
                  />
                </div>
                <div class="">
                  <label class="labelflo color-1" for="inputflo"><span class="e_text">Fueras de lugar</span></label>
                  <b-form-input
                    v-model="fl_o_form"
                    required
                    
                    id="inputflo"
                    pattern="\d*"
                    type="text"
                  />
                </div>
              </div>
              <div class="row mx-0 row-one mtrow">
                <div class="">
                  <label class="labelftsc color-1" for="inputftsc"><span class="e_text">Faltas</span></label>
                  <b-form-input
                    v-model="fts_c_form"
                    required
                    id="inputftsc"
                    type="text"
                    pattern="\d*"
                  />
                </div>
                <div class="">
                  <label class="labelftso color-1" for="inputftso"><span class="e_text">Faltas</span></label>
                  <b-form-input
                    v-model="fts_o_form"
                    required
                    
                    id="inputftso"
                    pattern="\d*"
                    type="text"
                  />
                </div>
              </div>
              <div class="row mx-0 row-one mtrow">
                <div class="">
                  <label class="labeltac color-1" for="inputtac"><span class="e_text">Tarjetas amarillas</span></label>
                  <b-form-input
                    v-model="ta_c_form"
                    required
                    id="inputtac"
                    type="text"
                    pattern="\d*"
                  />
                </div>
                <div class="">
                  <label class="labeltao color-1" for="inputtao"><span class="e_text">Tarjetas amarillas</span></label>
                  <b-form-input
                    v-model="ta_o_form"
                    required
                    
                    id="inputtao"
                    pattern="\d*"
                    type="text"
                  />
                </div>
              </div>
              <div class="row mx-0 row-one mtrow">
                <div class="">
                  <label class="labeltrc color-1" for="inputtrc"><span class="e_text">Tarjetas rojas</span></label>
                  <b-form-input
                    v-model="tr_c_form"
                    required
                    id="inputtrc"
                    type="text"
                    pattern="\d*"
                  />
                </div>
                <div class="">
                  <label class="labeltro color-1" for="inputtro"><span class="e_text">Tarjetas rojas</span></label>
                  <b-form-input
                    v-model="tr_o_form"
                    required
                    
                    id="inputtro"
                    pattern="\d*"
                    type="text"
                  />
                </div>
              </div>

             
              

             
            
             


               <div  v-if="this.section=='estadisticas'" class="row mx-0 row-one">
                <div class="div-error" v-if="this.status=='error'">
                    <p class="msg-error">{{this.msg}}</p>
                </div>
              </div>

              <div class="btn-form Last_Guardar">
                <b-btn 
                  variant="outline-secondary"
                  class="editar-modal "
                  type="submit"
                  >GUARDAR</b-btn
                >
                <b-modal
                  id="modal-editar"
                  ref="modal-editar"
                  no-close-on-backdrop
                  centered
                  hide-footer
                  hide-header
                >
                  <div class="message-modal">
                    <img :src="IconSuccess" class="success" /><br />
                    <p class="text-modal color-6">{{message_modal}}</p>
                  </div>
                  <div class="">
                    <b-button class="btn-modal" @click="editarOtro()"
                      >ACEPTAR</b-button
                    >
                    <b-button
                      type="button"
                      aria-label="Close"
                      class="close-modal"
                      @click="salir()"
                      >×</b-button
                    >
                  </div>
                </b-modal>
              </div>
            </form>
             </div>
             <div class="estadisticas" v-else>
                <label class="labelpr color-1" >Editar Estadisticas</label>

               <form
              @submit.prevent="
                editarEstadisticas(
                  data.estadisticas.id,
                  data.estadisticas.tag_c,
                  data.estadisticas.tde_c,
                  data.estadisticas.fl_c,
                  data.estadisticas.fts_c,
                  data.estadisticas.ta_c,
                  data.estadisticas.tr_c,
                  data.estadisticas.tag_o,
                  data.estadisticas.tde_o,
                  data.estadisticas.fl_o,
                  data.estadisticas.fts_o,
                  data.estadisticas.ta_o,
                  data.estadisticas.tr_o
                )
              "
            >
              <div class="row mx-0 row-one mtrow">
                <div class="">
                  <label id="label_subtitle" class="labelCim color-1">Cimarrones</label>
                 
                </div>
                <div class="">
                  <label id="label_subtitle" class="labelOp color-1" >Oponente</label>
                </div>
              </div>
               
               <div class="row mx-0 row-one">
                <div class="">
                  <label class="labelTagc color-1" for="inputTagc"><span class="e_text">Tiros a gol</span></label>
                  <b-form-input
                    v-model="data.estadisticas.tag_c"
                    required
                    id="inputTagc"
                    type="text"
                    pattern="\d*"
                  />
                </div>
                <div class="">
                  <label class="labelTago color-1" for="inputTago"><span class="e_text">Tiros a gol</span></label>
                  <b-form-input
                    v-model="data.estadisticas.tag_o"
                    required
                    
                    id="inputTago"
                    pattern="\d*"
                    type="text"
                  />
                </div>
              </div>

               <div class="row mx-0 row-one mtrow">
                <div class="">
                  <label class="labelTdec color-1" for="inputTdec"><span class="e_text">Tiros de esquina</span></label>
                  <b-form-input
                    v-model="data.estadisticas.tde_c"
                    required
                    id="inputTdec"
                    type="text"
                    pattern="\d*"
                  />
                </div>
                <div class="">
                  <label class="labelTdeo color-1" for="inputTdeo"><span class="e_text">Tiros de esquina</span></label>
                  <b-form-input
                    v-model="data.estadisticas.tde_o"
                    required
                    
                    id="inputTdeo"
                    pattern="\d*"
                    type="text"
                  />
                </div>
              </div>
               <div class="row mx-0 row-one mtrow">
                <div class="">
                  <label class="labelflc color-1" for="inputflc"><span class="e_text">Fueras de lugar</span></label>
                  <b-form-input
                    v-model="data.estadisticas.fl_c"
                    required
                    id="inputflc"
                    type="text"
                    pattern="\d*"
                  />
                </div>
                <div class="">
                  <label class="labelflo color-1" for="inputflo"><span class="e_text">Fueras de lugar</span></label>
                  <b-form-input
                    v-model="data.estadisticas.fl_o"
                    required
                    
                    id="inputflo"
                    pattern="\d*"
                    type="text"
                  />
                </div>
              </div>
              <div class="row mx-0 row-one mtrow">
                <div class="">
                  <label class="labelftsc color-1" for="inputftsc"><span class="e_text">Faltas</span></label>
                  <b-form-input
                    v-model="data.estadisticas.fts_c"
                    required
                    id="inputftsc"
                    type="text"
                    pattern="\d*"
                  />
                </div>
                <div class="">
                  <label class="labelftso color-1" for="inputftso"><span class="e_text">Faltas</span></label>
                  <b-form-input
                    v-model="data.estadisticas.fts_o"
                    required
                    
                    id="inputftso"
                    pattern="\d*"
                    type="text"
                  />
                </div>
              </div>
              <div class="row mx-0 row-one mtrow">
                <div class="">
                  <label class="labeltac color-1" for="inputtac"><span class="e_text">Tarjetas amarillas</span></label>
                  <b-form-input
                    v-model="data.estadisticas.ta_c"
                    required
                    id="inputtac"
                    type="text"
                    pattern="\d*"
                  />
                </div>
                <div class="">
                  <label class="labeltao color-1" for="inputtao"><span class="e_text">Tarjetas amarillas</span></label>
                  <b-form-input
                    v-model="data.estadisticas.ta_o"
                    required
                    
                    id="inputtao"
                    pattern="\d*"
                    type="text"
                  />
                </div>
              </div>
              <div class="row mx-0 row-one mtrow">
                <div class="">
                  <label class="labeltrc color-1" for="inputtrc"><span class="e_text">Tarjetas rojas</span></label>
                  <b-form-input
                    v-model="data.estadisticas.tr_c"
                    required
                    id="inputtrc"
                    type="text"
                    pattern="\d*"
                  />
                </div>
                <div class="">
                  <label class="labeltro color-1" for="inputtro"><span class="e_text">Tarjetas rojas</span></label>
                  <b-form-input
                    v-model="data.estadisticas.tr_o"
                    required
                    
                    id="inputtro"
                    pattern="\d*"
                    type="text"
                  />
                </div>
              </div>

             
              

             
            
             


               <div  v-if="this.section=='estadisticas'" class="row mx-0 row-one">
                <div class="div-error" v-if="this.status=='error'">
                    <p class="msg-error">{{this.msg}}</p>
                </div>
              </div>

              <div id="Estadisticabtn_guardar" class="btn-form">
                <b-btn 
                  variant="outline-secondary"
                  class="editar-modal"
                  type="submit"
                  >GUARDAR</b-btn
                >
                <b-modal
                  id="modal-editar"
                  ref="modal-editar"
                  no-close-on-backdrop
                  centered
                  hide-footer
                  hide-header
                >
                  <div class="message-modal">
                    <img :src="IconSuccess" class="success" /><br />
                    <p class="text-modal color-6">{{message_modal}}</p>
                  </div>
                  <div class="">
                    <b-button class="btn-modal" @click="editarOtro()"
                      >ACEPTAR</b-button
                    >
                    <b-button
                      type="button"
                      aria-label="Close"
                      class="close-modal"
                      @click="salir()"
                      >×</b-button
                    >
                  </div>
                </b-modal>
              </div>
            </form>
             </div>
            </div>
            <div class="estadisticas" v-else>
              <label class="color-1">Registro de estadisticas:</label>
              <label class="color-1"><span class="e_text">disponible {{convertDate(data.descrp)}}</span></label> 
            </div>
          
           




           
          <!--- Modal de errores  --->
          <b-modal
            id="modal-error"
            ref="modal-error"
            no-close-on-backdrop
            centered
            hide-footer
            hide-header
          >
            <div class="message-modal">
              <img :src="closeIcon" class="icon3 pt-5"><br>
              <p class="text-modal">{{msg}}</p>
            </div>
            <div class="">
              <b-button class="btn-modal" @click="wait()"
                >Aceptar</b-button
              >
              <b-button
                type="button"
                aria-label="Close"
                class="close-modal"
                @click="wait()"
                >×</b-button
              >
            </div>
          </b-modal>
          <!---  
             {{videos}}
             {{blogs}}
             {{gallerys}} --->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import SideMenu from '../../components/SideMenu';
import logo from '../../assets/cimarrones/logocima_2.png';
import IconSuccess from "../../assets/Vector.png";
import legrafica from "../../assets/Legrafica.png";
import categorias from '../../assets/categorias.png';
import usuarios from '../../assets/usuarios.png';
import productos from '../../assets/productos.png';
import { mapActions } from "vuex";
import i_logout from '../../assets/iconocerrar.png';
import Header from '../../components/Header';

let urlpath = require('../../global/url');
import dayjs  from 'dayjs';
import { es } from "dayjs/locale/es";
var relativeTime = require('dayjs/plugin/relativeTime')
export default {
  name: "EditUser",
    components: {
    Header,
    SideMenu,
  },
  data() {
    return {
      fecha:"",
      status: "",
      msg:"",
      msgimg: "success",
      file1:null,
      logo: logo,
      legrafica: legrafica,
      categorias: categorias,
      usuarios: usuarios,
      productos: productos,
      IconSuccess: IconSuccess,
      closeIcon: i_logout, 

      profile_form:"",
      team_form:"",
      tournament_form:"",
     
      channel_form:"",
      channel_name:"canal",

      videos_form:"",
      videos_name:"video",

      blog_form:"",
      blog_name:"blog",

      gallery_form:"",
      gallery_name:"galeria",

      urlpath,
      section:'',


      tag_c_form:0,
      tde_c_form:0,
      fl_c_form:0,
      fts_c_form:0,
      ta_c_form:0,
      tr_c_form:0,
      tag_o_form:0,
      tde_o_form:0,
      fl_o_form:0,
      fts_o_form:0,
      ta_o_form:0,
      tr_o_form:0,

      message_modal: "Cambios realizados con exito."

    };
  },async beforeMount() {
        let token = await this.$store.dispatch("admin/getToken");
        this.$store.dispatch("admin/decode", token);
    },
  created() {
    let id = this.$route.params.id;
    let payload = {
      id: id,
      option: "calendar",
    };
    this.getInfoById(payload);
    this.getProfiles('perfiles')
    this.getTournaments('torneos')
    this.getTeams('equipos')
    this.getChannels('canales')
    this.getVideos('videos')
    this.getBlogs('blog')
    this.getGallerys('galeria')

    let payload2 ={
      id: id,
      option:'channels_calendar/calendar'
    }
    this.getlistChannelsCalendarById(payload2)
  },
  computed: {
     teams() {
          return this.$store.getters["teams/data"];
    },
    tournaments() {
      return this.$store.getters["tournament/data"];
    },
    profiles() {
      return this.$store.getters["profile/data"];
    },
    channels() {
      return this.$store.getters["channel/data"];
    },
    listChannels(){
      return this.$store.getters["listChannelsCalendar/data"];
    },
    data() {
      return this.$store.getters["main/data"];
    },
     url() {
      return this.$store.getters["main/baseURL"];
    },
    user() {            
            return this.$store.getters['admin/getIdentity'];
        },
    videos(){
       return this.$store.getters["videos/data"];
    },
    blogs(){
       return this.$store.getters["blog3/data"];
    },
    gallerys(){
       return this.$store.getters["gallery2/data"];
    }
  },
  methods: {
     ...mapActions("main", ["getInfoById"]),
     ...mapActions('channel', ['getChannels']),
     ...mapActions('videos', ['getVideos']),
     ...mapActions('gallery2', ['getGallerys']),
     ...mapActions('blog3', ['getBlogs']),
     ...mapActions('videos', ['getVideos']),
     ...mapActions('listChannelsCalendar', ['getlistChannelsCalendarById']),
     ...mapActions('profile', ['getProfiles']),
     ...mapActions('teams', ['getTeams']),
     ...mapActions('tournament', ['getTournaments']),
    wait: function () {
      setTimeout(() => this.$router.go(), 200);
    },
    showModalError() {
            this.$refs["modal-error"].show();
         },
    eliminarCanal: async function(id){
      
      let option ='channels_calendar'
      let result = await this.$store.dispatch("main/deleteItem", {option: option, id:id});
              
      if(result.status=='error'){
        this.section='canales'
        this.msg = result.message;
        this.showModalError();
      }else{
        this.section==''
        this.msg='';
        this.wait()
      }
    },
    eliminarVideo: async function(id){
      
      let option ='calendar/video'
      let result = await this.$store.dispatch("main/deleteItem", {option: option, id:id});
              
      if(result.status=='error'){
        this.section='videos'
        this.msg = result.message;
        this.showModalError();
      }else{
        this.section==''
        this.msg='';
        this.wait()
      }
    },
    eliminarBlog: async function(id){
      
      let option ='calendar/blog'
      let result = await this.$store.dispatch("main/deleteItem", {option: option, id:id});
              
      if(result.status=='error'){
        this.section='blog'
        this.msg = result.message;
        this.showModalError();
      }else{
        this.section==''
        this.msg='';
        this.wait()
      }
    },

    eliminarGallery: async function(id){
      
      let option ='calendar/gallery'
      let result = await this.$store.dispatch("main/deleteItem", {option: option, id:id});
              
      if(result.status=='error'){
        this.section='gallery'
        this.msg = result.message;
        this.showModalError();
      }else{
        this.section==''
        this.msg='';
        this.wait()
      }
    },
    agregarEstadisticas: async function(tag_c, tde_c, fl_c, fts_c, ta_c, tr_c, tag_o, tde_o, fl_o, fts_o, ta_o, tr_o){
      let id_calendar = this.$route.params.id;
       this.message_modal ='Las estadisticas se agregaron correctamente.'



      let data = {
        id_calendar: id_calendar,
        tag_c : String(tag_c), 
        tde_c : String(tde_c), 
        fl_c  : String(fl_c), 
        fts_c : String(fts_c), 
        ta_c  : String(ta_c), 
        tr_c  : String(tr_c), 
        tag_o : String(tag_o), 
        tde_o : String(tde_o), 
        fl_o  : String(fl_o), 
        fts_o : String(fts_o), 
        ta_o  : String(ta_o), 
        tr_o  : String(tr_o)
      }

       this.status=''
       this.msg=''
       
      let result = await this.$store.dispatch("main/addItem",  {option:'estadisticas', item: data});
    
      if(result.status=='error'){
        this.section='estadisticas'
        this.status='error'
        this.msg= result.message
      }else{ // success
          this.showModal() 
      }

        


    },
    editarEstadisticas: async function(id, tag_c, tde_c, fl_c, fts_c, ta_c, tr_c, tag_o, tde_o, fl_o, fts_o, ta_o, tr_o){
      let id_calendar = this.$route.params.id;
       this.message_modal ='Las estadisticas se editaron correctamente.'



      let data = {
        id_calendar: id_calendar,
        id    : String(id),
        tag_c : String(tag_c), 
        tde_c : String(tde_c), 
        fl_c  : String(fl_c), 
        fts_c : String(fts_c), 
        ta_c  : String(ta_c), 
        tr_c  : String(tr_c), 
        tag_o : String(tag_o), 
        tde_o : String(tde_o), 
        fl_o  : String(fl_o), 
        fts_o : String(fts_o), 
        ta_o  : String(ta_o), 
        tr_o  : String(tr_o)
      }

       this.status=''
       this.msg=''
       
      let result = await this.$store.dispatch("main/editItem",  {option:'estadisticas', item: data});
    
      if(result.status=='error'){
        this.section='estadisticas'
        this.status='error'
        this.msg= result.message
      }else{ // success
          this.showModal() 
      }

        


    },
    agregarCanal: async function(id_channel){
        let id_calendar = this.$route.params.id;
       this.message_modal ='El canal se agrego correctamente.'

        /*
        console.log(id_calendar)
        console.log(id_channel)*/

        if(id_channel == '' || id_channel == undefined || id_channel==null){
                this.section='canales'
               this.status='error'
              this.msg='Elige un canal.'
        }else{

          id_calendar= String(id_calendar)
          id_channel = String(id_channel)
          let data={
            id_channel:id_channel,
            id_calendar:id_calendar
          }

          this.status=''
          this.msg=''
       
          let result = await this.$store.dispatch("main/addItem",  {option:'channels_calendar', item: data});
        
          if(result.status=='error'){
            this.section='canales'
            this.status='error'
            this.msg= result.message
          }else{ // success
              this.showModal() 
          }
        }
    },
    agregarBlog: async function(id_blog){
        let id_calendar = this.$route.params.id;
       this.message_modal ='El blog se agrego correctamente.'



        if(id_blog == '' || id_blog == undefined || id_blog==null){
                this.section='blog'
               this.status='error'
              this.msg='Elige un blog.'
        }else{

          id_calendar= String(id_calendar)
          id_blog = String(id_blog)
          let data={
            id_blog:id_blog,
            id_calendar:id_calendar
          }

          this.status=''
          this.msg=''
       
          let result = await this.$store.dispatch("main/addItem",  {option:'calendar/blog', item: data});
        
          if(result.status=='error'){
            this.section='blog'
            this.status='error'
            this.msg= result.message
          }else{ // success
              this.showModal() 
          }
        }
    },
    agregarVideo: async function(id_videos){
        let id_calendar = this.$route.params.id;
       this.message_modal ='El video se agrego correctamente.'



        if(id_videos == '' || id_videos == undefined || id_videos==null){
                this.section='videos'
               this.status='error'
              this.msg='Elige un video.'
        }else{

          id_calendar= String(id_calendar)
          id_videos = String(id_videos)
          let data={
            id_videos:id_videos,
            id_calendar:id_calendar
          }

          this.status=''
          this.msg=''
       
          let result = await this.$store.dispatch("main/addItem",  {option:'calendar/video', item: data});
        
          if(result.status=='error'){
            this.section='videos'
            this.status='error'
            this.msg= result.message
          }else{ // success
              this.showModal() 
          }
        }
    },
    agregarGallery: async function(id_gallery){
        let id_calendar = this.$route.params.id;
       this.message_modal ='La galeria se agrego correctamente.'



        if(id_gallery == '' || id_gallery == undefined || id_gallery==null){
                this.section='gallery'
               this.status='error'
              this.msg='Elige una galeria.'
        }else{

          id_calendar= String(id_calendar)
          id_gallery = String(id_gallery)
          let data={
            id_gallery:id_gallery,
            id_calendar:id_calendar
          }

          this.status=''
          this.msg=''
       
          let result = await this.$store.dispatch("main/addItem",  {option:'calendar/gallery', item: data});
        
          if(result.status=='error'){
            this.section='gallery'
            this.status='error'
            this.msg= result.message
          }else{ // success
              this.showModal() 
          }
        }
    },
    submit: async function (opname, descrp, result_c, result_op, hr, type, typegame, timezone, stadium,  image, id_team, id_tournament,id_profile) {
       let id = this.$route.params.id;
       let calendar = this.data

      

        if(this.compareDate()){
            if(result_c > 0 || result_op >0 ){
             this.status='error'
             this.msg= 'Este partido aun no se ha jugado.'
             return 'error'
            }
        }

       result_c = String(result_c)
       result_op = String(result_op)
       this.message_modal ='El partido se edito correctamente.'
      
     
        if(descrp=='' || descrp == null || descrp == undefined || descrp == ' '){
          descrp = calendar.descrp
        }
      
      

        //validar que no vengan vacios.
        id_profile== undefined ||  id_profile== ''  ||  id_profile== null  ? id_profile =calendar.id_profile : id_profile;
        id_team== undefined || id_team== ''  || id_team== null  ?id_team =calendar.id_team:id_team;
        id_tournament== undefined || id_tournament== ''  || id_tournament== null  ?id_tournament =calendar.id_tournament :id_tournament;

        id_tournament = String(id_tournament)
        id_profile =  String(id_profile)
        id_team = String(id_team)



        this.status=''
        this.msg=''

        let datos = {
          opname:opname, descrp:descrp, result_c:result_c, result_op:result_op, hr:hr, type:type, typegame:typegame, timezone:timezone, stadium:stadium,  image:image, id_team:id_team, id_tournament:id_tournament,id_profile:id_profile
        }
             
       //console.log(datos)
         
       if(image !=null){

           if(image.size > 1000000){
                this.status='error'
                this.msg= 'Tamaño de imagen no válido, sube imagenes menores a 1 MB.'
               
                
            }else{
           
            
          var data = new  FormData();
          //Añadimos la imagen seleccionada
          data.append('id', id)
          data.append('image', image);
          data.append('opname', opname);
          data.append('descrp', descrp);
          data.append('hr',hr);
          data.append('typegame', typegame);
          data.append('type', type);
          data.append('stadium', stadium);
          data.append('timezone', timezone);
           data.append('result_c', result_c);
          data.append('result_op', result_op);
           data.append('id_tournament', id_tournament);
          data.append('id_profile', id_profile);
          data.append('id_team', id_team);
          data.append('_method', 'PUT');
          //Enviamos la petición
        

          
          let result = await  this.$store.dispatch("main/editItem",  {option:'calendar', item:data});
            
              if(result.status=='error'){
                this.status='error'
                this.msg= result.message
              }else{ // success
                  this.showModal();
              }
          
            }
          

      }else{
       
        let partido ={
          "opname": opname,
          "descrp":descrp,
          "hr": hr,
          "type":type,
          "typegame":typegame,
          "stadium":stadium,
          "timezone":timezone,
          "result_c":result_c,
          "result_op":result_op,
          "id_team":id_team,
          "id_tournament":id_tournament,
          "id_profile":id_profile,
          "id":id
        }
      
      let result = await  this.$store.dispatch("main/editItem",  {option:'calendar', item:partido});
         
          if(result.status=='error'){
            this.status='error'
            this.msg= result.message
          }else{ // success
              this.showModal();
          }
        
      
      }

       
     
    },
    editarOtro: function () {
      this.wait();
    },
    salir: function () {
       this.$router.push("/administrador").catch((err) => {});
    },
    changeType: function (value) {
      if(value =='Local'){
        this.data.stadium ='Héroe de Nacozari'
      }else{
        this.data.stadium =''
      }
      this.data.type = value;
    },
    showModal() {
      this.$refs["modal-editar"].show();
    },
    image: function(img){
        let src = this.url +'/get-calendar-image/'+img;
         this.status_img(img)
        return src
         },
    status_img:async  function (img){
    let userimg =''
      try{
          userimg = await this.$store.dispatch("main/getImage",{image: img, option:'calendar'})
          if(userimg.data.status=='error'){
              this.msgimg='error'
          }else{
              this.msgimg='success'
          }
      }catch(err){
          this.msgimg='error'
      }
    },
     changeTournament: function (value, name) {
     
       this.tournament_form = value;
      this.data.name_tournament = name;

    },
     changeTeam: function (value, name) {
     
       this.team_form = value;
      this.data.name_team = name;

    },
     changeProfile: function (value, name) {
     
       this.profile_form = value;
       this.data.name_profile = name;

    },
    changeChannel: function (value, name) {
       this.channel_form = value;
       this.channel_name = name;
    },

    changeVideos: function (value, name) {
       this.videos_form = value;
       this.videos_name = name;
    },

    changeBlog: function (value, name) {
  
       this.blog_form = value;
       this.blog_name = name;
    },

    changeGallery: function (value, name) {
     
       this.gallery_form = value;
       this.gallery_name = name;

    },
    compareDate: function(){
       let day = dayjs().format('YYYY-MM-DD')
       let calendarDate = this.data.descrp

       if(day < calendarDate){
         return true
       }else{
         return false
       }
    },

     convertDate(date){
         dayjs.extend(relativeTime)
         dayjs.locale("es");
         
          /*let dateSplit = date.split('/')
          let yy = dateSplit[2]
          let mm = dateSplit[1]
          let dd = dateSplit[0]
          date = yy+'-'+mm+'-'+dd*/

          return dayjs(date).fromNow() 
         },

 
    
  },
};
</script>


